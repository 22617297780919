<template>
<div>
  <h1> Security </h1>
</div>
</template>
<script>
  export default {
    data() {
      return {

      }
    }
  }
</script>
